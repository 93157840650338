import gql from "graphql-tag";
import { me } from "./fragments";
export const queryMe = gql`
    query Me {
        me {
            ...me
        }
        unbecomeAvailable
    }
    ${me}
`;
