import gql from "graphql-tag";
const session = gql`
    fragment session on UserSession {
        id
        screenHeight
        screenWidth
        screenPixelRatio
        networkType
        osVersion
        platform
    }
`;
export const me = gql`
    fragment me on User {
        id
        email
        name
        firstName
        initials
        avatarImage(width: 64, height: 64)
        role
        requestedTotalsInOpenCartOrder {
            currency
            itemsPrice
            quantity
            savedPrice
            savedByDiscountPrice
            savedByCashbackPrice
        }
        marketingConsent: consented(consentType: MARKETING)
        sellers {
            edges {
                node {
                    id
                    seller {
                        id
                        title
                    }
                }
            }
        }
        credits {
            currency
            balance
        }
        session {
            ...session
        }
        activeVenue {
            id
            slug
        }
        preferredHomeDelivery {
            id
        }
        activeCollectionOrigin {
            id
        }
        activeWeekday
        currentCartOrder {
            id
        }
        # needed for observable query in main.jsx
        venuesUserIsOwner: venues(requireVenueMembership: true, filter: {memberTypes: [OWNER]}) {
            count
        }
        hasActiveUserFeatureFlag
    }
    ${session}
`;
export const wmsUserLeases = gql`
    fragment WMSUserLeases on User {
        id
        leases {
            edges {
                node {
                    contextId
                    leaseType
                    leasedNode {
                        __typename
                        ... on DeliveryContainer {
                            id
                            destination {
                                __typename
                                ... on DeliveryZone {
                                    id
                                    delivery {
                                        id
                                        warehouse {
                                            id
                                        }
                                    }
                                    productionDelivery {
                                        id
                                        warehouse {
                                            id
                                        }
                                    }
                                }
                            }
                        }
                        ... on Product {
                            id
                        }
                    }
                }
            }
        }
    }
`;
export const productTypesFragment = gql`
    fragment ProductTypes__productTypes on Query {
        productTypes(filter: {withActiveProducts: true, upToLevel: 2}) {
            edges {
                node {
                    id
                    title
                    slug
                    level
                    lft
                    rgt
                    dropdownLayout
                    image(width: 128, height: 128)
                }
            }
        }
    }
`;
