function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
import gql from "graphql-tag";
import { lru } from "tiny-lru";
import { HOME_DELIVERY_PAGE, PRODUCT_PREPARED_FILTER_PAGE, PRODUCT_PREPARED_FILTER_PRODUCT_DETAIL_PAGE, PRODUCT_PREPARED_FILTER_PRODUCT_TYPE_PAGE, PRODUCT_SELLER_PAGE, PRODUCT_SELLER_PRODUCT_DETAIL_PAGE, PRODUCT_SELLER_PRODUCT_TYPE_PAGE, PRODUCT_TYPE_PAGE, PRODUCT_TYPE_PRODUCT_DETAIL_PAGE, SLUG2_PAGE, SLUG2_PRODUCT_DETAIL_PAGE, SLUG_PAGE, SLUG_PRODUCT_DETAIL_PAGE, VENUE_PAGE, VENUE_PRODUCT_DETAIL_PAGE, VENUE_PRODUCT_TYPE_PAGE } from "../gen-src/router";
const slugCache = lru(256, 1000 * 60 * 4, false);
function querySlugPage(apolloClient, slug) {
    return _querySlugPage.apply(this, arguments);
}
function _querySlugPage() {
    _querySlugPage = _async_to_generator(function*(apolloClient, slug) {
        const cached = slugCache.get(slug);
        if (cached !== undefined) {
            return cached.page;
        }
        const { data } = yield apolloClient.query({
            query: gql`
            query SlugPage($slug: String!) {
                page: slug(slug: $slug) {
                    __typename
                    id
                    ... on Redirect {
                        node {
                            id
                            slug
                        }
                        url
                    }
                }
            }
        `,
            variables: {
                slug: slug
            }
        });
        if (data !== undefined) {
            slugCache.set(slug, data);
        }
        return data.page;
    });
    return _querySlugPage.apply(this, arguments);
}
export function rerouteSlugPage(request, apolloClient) {
    return _rerouteSlugPage.apply(this, arguments);
}
function _rerouteSlugPage() {
    _rerouteSlugPage = _async_to_generator(function*(request, apolloClient) {
        if (request.route === SLUG_PAGE) {
            const page = yield querySlugPage(apolloClient, request.attributes.slug);
            if (!page) {
                return request;
            }
            switch(page.__typename){
                case "ProductSeller":
                    return _extends({}, request, {
                        route: PRODUCT_SELLER_PAGE,
                        attributes: _extends({}, request.attributes, {
                            slug: undefined,
                            productSeller: request.attributes.slug
                        })
                    });
                case "HomeDeliveryView":
                    return _extends({}, request, {
                        route: HOME_DELIVERY_PAGE,
                        attributes: _extends({}, request.attributes, {
                            slug: undefined
                        })
                    });
                case "Venue":
                    return _extends({}, request, {
                        route: VENUE_PAGE,
                        attributes: _extends({}, request.attributes, {
                            slug: undefined,
                            venue: request.attributes.slug
                        })
                    });
                case "ProductType":
                    return _extends({}, request, {
                        route: PRODUCT_TYPE_PAGE,
                        attributes: _extends({}, request.attributes, {
                            slug: undefined,
                            productType: request.attributes.slug
                        })
                    });
                case "ProductPreparedFilter":
                    return _extends({}, request, {
                        route: PRODUCT_PREPARED_FILTER_PAGE,
                        attributes: _extends({}, request.attributes, {
                            slug: undefined,
                            productPreparedFilter: request.attributes.slug
                        })
                    });
                case "Redirect":
                    const redirect = page;
                    if (redirect.node != null) {
                        return {
                            redirect: redirect.node.slug
                        };
                    } else if (redirect.url != null) {
                        return {
                            redirectUrl: redirect.url
                        };
                    } else {
                        throw `Unhandled redirect [${JSON.stringify(redirect)}].`;
                    }
                default:
                    throw `Unhandled slug type.`;
            }
        } else if (request.route === SLUG_PRODUCT_DETAIL_PAGE) {
            const response = yield querySlugPage(apolloClient, request.attributes.slug);
            if (!response) {
                return request;
            }
            const { __typename: __typename } = response;
            switch(__typename){
                case "ProductType":
                    return _extends({}, request, {
                        route: PRODUCT_TYPE_PRODUCT_DETAIL_PAGE,
                        attributes: _extends({}, request.attributes, {
                            slug: undefined,
                            productType: request.attributes.slug
                        })
                    });
                case "ProductPreparedFilter":
                    return _extends({}, request, {
                        route: PRODUCT_PREPARED_FILTER_PRODUCT_DETAIL_PAGE,
                        attributes: _extends({}, request.attributes, {
                            slug: undefined,
                            productPreparedFilter: request.attributes.slug
                        })
                    });
                default:
                    throw `Unhandled slug type [${__typename}].`;
            }
        } else if (request.route === SLUG2_PAGE) {
            const promise1 = querySlugPage(apolloClient, request.attributes.slug);
            const promise2 = querySlugPage(apolloClient, request.attributes.slug2);
            const [response1, response2] = yield Promise.all([
                promise1,
                promise2
            ]);
            if (!response1 || !response2) {
                return request;
            }
            const { __typename: __typename } = response1;
            const { __typename: __typename2 } = response2;
            switch(__typename){
                case "ProductSeller":
                    switch(__typename2){
                        case "ProductType":
                            return _extends({}, request, {
                                route: PRODUCT_SELLER_PRODUCT_TYPE_PAGE,
                                attributes: _extends({}, request.attributes, {
                                    slug: undefined,
                                    slug2: undefined,
                                    productSeller: request.attributes.slug,
                                    productType: request.attributes.slug2
                                })
                            });
                        case "Redirect":
                            const redirect2 = response2;
                            return {
                                redirect: request.attributes.slug,
                                redirect2: redirect2.node.slug
                            };
                        default:
                            throw `Unhandled slug [${__typename2}] for ProductSeller [${request.attributes.slug}].`;
                    }
                case "HomeDeliveryView":
                case "Venue":
                    switch(__typename2){
                        case "ProductType":
                            return _extends({}, request, {
                                route: VENUE_PRODUCT_TYPE_PAGE,
                                attributes: _extends({}, request.attributes, {
                                    slug: undefined,
                                    slug2: undefined,
                                    venue: request.attributes.slug,
                                    productType: request.attributes.slug2
                                })
                            });
                        case "Redirect":
                            const redirect21 = response2;
                            return {
                                redirect: request.attributes.slug,
                                redirect2: redirect21.node.slug
                            };
                        default:
                            throw `Unhandled slug [${__typename2}] for Venue [${request.attributes.slug}].`;
                    }
                case "ProductPreparedFilter":
                    switch(__typename2){
                        case "ProductType":
                            return _extends({}, request, {
                                route: PRODUCT_PREPARED_FILTER_PRODUCT_TYPE_PAGE,
                                attributes: _extends({}, request.attributes, {
                                    slug: undefined,
                                    slug2: undefined,
                                    productPreparedFilter: request.attributes.slug,
                                    productType: request.attributes.slug2
                                })
                            });
                        case "Redirect":
                            const redirect22 = response2;
                            return {
                                redirect: request.attributes.slug,
                                redirect2: redirect22.node.slug
                            };
                        default:
                            throw `Unhandled slug [${__typename2}] for Venue [${request.attributes.slug}].`;
                    }
                case "Redirect":
                    const redirect1 = response1;
                    switch(__typename2){
                        case "Redirect":
                            const redirect23 = response2;
                            return {
                                redirect: redirect1.node.slug,
                                redirect2: redirect23.node.slug
                            };
                        default:
                            return {
                                redirect: redirect1.node.slug,
                                redirect2: request.attributes.slug2
                            };
                    }
                default:
                    throw `Unhandled slug type [${__typename}].`;
            }
        } else if (request.route === SLUG2_PRODUCT_DETAIL_PAGE) {
            const promise1 = querySlugPage(apolloClient, request.attributes.slug);
            const promise2 = querySlugPage(apolloClient, request.attributes.slug2);
            const [response1, response2] = yield Promise.all([
                promise1,
                promise2
            ]);
            if (!response1 || !response2) {
                return request;
            }
            const { __typename: __typename } = response1;
            const { __typename: __typename2 } = response2;
            switch(__typename){
                case "ProductSeller":
                    switch(__typename2){
                        case "ProductType":
                            return _extends({}, request, {
                                route: PRODUCT_SELLER_PRODUCT_DETAIL_PAGE,
                                attributes: _extends({}, request.attributes, {
                                    slug: undefined,
                                    slug2: undefined,
                                    productSeller: request.attributes.slug,
                                    productType: request.attributes.slug2
                                })
                            });
                        case "Redirect":
                            const redirect2 = response2;
                            return {
                                redirect: request.attributes.slug,
                                redirect2: redirect2.node.slug,
                                hash: request.attributes.hash
                            };
                        default:
                            throw `Unhandled slug [${__typename2}] for ProductSeller [${request.attributes.slug}].`;
                    }
                case "HomeDeliveryView":
                case "Venue":
                    switch(__typename2){
                        case "ProductType":
                            return _extends({}, request, {
                                route: VENUE_PRODUCT_DETAIL_PAGE,
                                attributes: _extends({}, request.attributes, {
                                    slug: undefined,
                                    slug2: undefined,
                                    venue: request.attributes.slug,
                                    productType: request.attributes.slug2
                                })
                            });
                        case "Redirect":
                            const redirect21 = response2;
                            return {
                                redirect: request.attributes.slug,
                                redirect2: redirect21.node.slug,
                                hash: request.attributes.hash
                            };
                        default:
                            throw `Unhandled slug [${__typename2}] for Venue [${request.attributes.slug}].`;
                    }
                case "Redirect":
                    const redirect1 = response1;
                    switch(__typename2){
                        case "Redirect":
                            const redirect22 = response2;
                            return {
                                redirect: redirect1.node.slug,
                                redirect2: redirect22.node.slug,
                                hash: request.attributes.hash
                            };
                        default:
                            return {
                                redirect: redirect1.node.slug,
                                redirect2: request.attributes.slug2,
                                hash: request.attributes.hash
                            };
                    }
                default:
                    throw `Unhandled slug type [${__typename}].`;
            }
        } else {
            throw `rerouteSlugPage: Unhandled route [${request.route}].`;
        }
    });
    return _rerouteSlugPage.apply(this, arguments);
}
